import globals from '../globals';
export default {
    data() {
        return {
            warning_msg: ''
        }
    },
    components: {
        globals
    },
    methods: { 
        async getAuctionsDetails(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.CRM_SALES_URL + `/auctions/`+params.auction_id+`?company_id=`+params.company_id+`&timezone=`+params.timezone);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async createBidderAPI(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.CRM_SALES_URL + '/auctions/bidder/info', params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                }
            })
        },
    },
}