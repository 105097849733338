<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default bidder-registartion"
        :name="modal_name"
        :delay="100"
        height="auto"
        :adaptive="true" 
        :scrollable="false"
        style="pointer-events:inherit !important" 
        v-bind:click-to-close="false">
        <div class="v-modal-content" style="border-radius: 15px;background-color: #fff !important;">
            <div class="v-modal-header pt-6">
                <div>
                    <span class="modal-reg-title">Register your details!</span>
                </div>
                <div @click="hideRegistrationModal()">
                    <span class="icon icon-close mr-2 text-black pointer close-right"></span>
                </div>
            </div>
            <div class="v-modal-body pr-8 pl-8">
                <p>Thanks for showing interest in our items. Kindly register your details. We will get back to you shortly.</p>
                <div class="reg-section">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Name<span class="text-danger fs-14">*</span></div>
                                    <div class="w-100" style="position: relative;">
                                        <input class="input-field-underline" name="bidder_name" v-model="bidder_name" v-validate="'required'" style="padding-top: 6px !important;" placeholder="Enter your name" maxlength="100">
                                        <div class="text-danger fs-12" style="text-transform: none !important;position: absolute;" v-show="errors.has('bidder_name')">Please Enter Name</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Company Name</div>
                                    <div class="w-100" style="position: relative;">
                                        <input class="input-field-underline" v-model="company_name" style="padding-top: 6px !important;" placeholder="Enter your company name" maxlength="100">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Phone Number<span class="text-danger fs-14">*</span></div>
                                    <div class="w-100" style="position: relative;">
                                        <vue-tel-input v-bind="bindProps"
                                            @onInput="phoneInput" class="input-field-underline ml-0"
                                            name="mobile_number" v-model="contact_mobile_number.phone_number"
                                            :valid-characters-only="true"
                                            v-validate="{required:true,min:10}"
                                            :maxLen="15" placeholder="Add Phone Number"
                                            :disabledFormatting="false" style="width:100% !important; margin-right: 30px;"></vue-tel-input>
                                        <div class="text-danger fs-12" style="text-transform: none !important;position: absolute;" v-show="errors.has('mobile_number')">Please Enter Phone Number</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Email Id<span class="text-danger fs-14">*</span></div>
                                    <div class="w-100" style="position: relative;">
                                        <input class="input-field-underline" v-validate="'required|email'" v-model="email_id" name="email_id" style="padding-top: 6px !important;" placeholder="Enter Email Id" maxlength="100">
                                        <div class="text-danger fs-12" style="text-transform: none !important;position: absolute;" v-show="errors.has('email_id')">Please Enter a Valid Email ID</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Interest</div>
                                    <div class="w-100" style="position: relative;">
                                        <input class="input-field-underline" v-model="interest" style="padding-top: 6px !important;" placeholder="Enter interest" maxlength="2000">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                            <div class="mt-3">
                                <div class="d-flex new-contactView ">
                                    <div class="fs-14 fw-600 text-secondary auction-content-left-text mt-1">Notes</div>
                                    <div class="w-100" style="position: relative;">
                                        <input class="input-field-underline" v-model="notes" style="padding-top: 6px !important;" placeholder="Add Notes" maxlength="2000">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-modal-footer mb-4 mt-2">
                <button class="btn send-btn-reg" @click="validateData()" :disabled="disable_btn">Send</button>
            </div>
        </div>
        <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
    </modal>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import auction from "../mixins/auction.js"
export default {
    data() {
        return {
            disable_btn: false,
            success_msg: "",
            warning_msg: "",
            user_time_zone: "",
            bindProps: {
                mode: "international",
                defaultCountry: "US"
            },
            bidder_name: "",
            company_name: "",
            email_id: "",
            interest: "",
            notes: "",
            phone: [
                {
                    country_code: "",
                    dial_code: "",
                    phone_number: "",
                    phone_type: "mobile"
                },
            ],
            contact_mobile_number: {
                phone_number: "",
                dial_code: "1",
                country_code: "US",
                phone_type: "mobile"
            },
        }
    },
    props: ['modal_name', 'bidding_items', 'auction_id', 'company_id'],
    mixins: [auction],
    components: {
        SweetModal
    },
    methods: {
        validateData(){
            this.$validator.validateAll().then(async result => {
                if(result){
                    this.createBidder()
                } 
            })
        },
        async createBidder(){
            try {
                this.disable_btn = true;
                let params = {
                    bidder_name: this.bidder_name,
                    company_name: this.company_name,
                    bidder_email: this.email_id,
                    phone_details: this.contact_mobile_number,
                    company_id: this.company_id,
                    interest: this.interest,
                    notes: this.notes,
                    auction_id: this.auction_id,
                    timezone: this.user_time_zone,
                    bidding_items: this.bidding_items
                }
                let response = await this.createBidderAPI(params)
                this.success_msg = response.message
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.saveAndhideRegistrationModal();
                    this.disable_btn = false;
                    this.$refs.success_modal.close();
                }, 3000);

            } catch (error) {
                this.warning_msg = error
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.disable_btn = false;
                    this.$refs.warning_modal.close();
                }, 3000);
            }
        },
        phoneInput({number,isValid,country}) {
            this.phone[0].number = number;
            this.phone[0].isValid = isValid;
            this.phone[0].country = country;
            this.contact_mobile_number.dial_code = this.phone[0].country.dialCode
            this.contact_mobile_number.country_code = this.phone[0].country.iso2
        },
        hideRegistrationModal(){
            this.$modal.hide(this.modal_name)
            this.$emit('hideRegistrationModal')
        },
        saveAndhideRegistrationModal(){
            this.$modal.hide(this.modal_name)
            this.$emit('saveAndhideRegistrationModal')
        },
    },
    mounted() {
        this.user_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    created() {
        const dict = {
            custom: {
                mobile_number: {
                    required: () => "Phone Number is required",
                    min: () => "Phone Number must contain more than 9 numbers",
                },
            }
        };
    },
}
</script>
<style>
    .send-btn-reg{
        background: #00448b;
        color: #fff;
        text-transform: capitalize;
        width: 150px;
        height: 40px;
        font-size: 20px;
        font-weight: 500;
    }
    .send-btn-reg:hover{
        color: #fff;
    }
    .reg-section{
        border: 1px solid black;
        border-radius: 6px;
        height: 320px;
        padding: 10px 30px 30px 30px !important;
        line-height: 30px;
        overflow: overlay !important;
    }
    .input-field-underline {
        border: none !important;
        border-bottom: solid 0.5px #cdd2da !important;
        background-color: #fff;
        padding: 0px 6px;
        width: 100%;
        font-size: 16px;
        height: 40px;
    }
    .input-field-underline::placeholder{
        font-size: 14px !important;
        color: #9b9b9b !important;
    }
    input:focus{
        outline: none;
    }
    .new-contactView {
        display: flex;
        align-items: left;
        position: relative;
    }
    .auction-content-left-text {
        width: 160px!important;
        min-width: 160px!important;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        color: #1f2021 !important;
        margin-top: 10px !important;
        text-align: left;
    }
</style>