<template>
    <div>
        <div class="auction">
            <div class="auction-header">
                <h2 class="text-white text-center">
                    Welcome to {{ table_data.company_name }}
                </h2>
            </div>
            <div class="auction-title" v-if="auction_data.is_auction_expired == false">
                Auction!
            </div>
            <div class="auction-list" v-if="auction_data.is_auction_expired == false">
                <div class="listitems">List of Bidding Items</div>
                <div class="auction-date">
                    End Date and Time: 
                    <span>
                        {{ auction_data.auction_end_date }} {{ auction_data.auction_end_time }}
                    </span>
                </div>
            </div>
            <div v-if="auction_data.is_auction_expired == true" class="mt-30">
                <img src="/img/auction.svg" class="mb-3" style="width: 25%!important; height: 30%!important;"/>
                <div class="text-center fs-20 fw-600 mt-8" style="color: #00448b;">The Auction has been expired!</div>
            </div>
            <div v-for="(table_info, table_info_index) in table_details" :key="table_info_index" v-else>
                <div class="auction-ci-module-header pt-4">
                    {{ table_info.table_name }}
                </div>
                <div class="auction-table">
                    <div class="table-responsive-md">
                        <table class="table table-sm table-bordered auction-data-table" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th style="width: 12% !important;text-align: center !important;">Select Items</th>
                                    <!-- <th style="width: 5% !important;">S.No</th> -->
                                    <th v-for="(headingData, header_index) in table_info.header_data" :key="header_index">
                                        <input type="text" class="table-list" disabled v-model="table_info.header_data[header_index]" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, table_data_index) in table_info.table_data" :key="table_data_index">
                                    <td style="width: 10% !important;text-align: center;">
                                        <label class="custom-control custom-checkbox digi-checkbox" style="width: 100% !important;padding-right: 0px !important;min-width: 100%;padding-left: 0px !important;">
                                            <input type="checkbox" class="custom-control-input" @change="selectOne(table_info, data, table_info_index, table_data_index)" v-model="data.checked"/>
                                            <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;width: 100% !important;"></span>
                                            <span class="form-check-description fw-600"></span>
                                        </label>
                                    </td>
                                    <!-- <td style="width: 5% !important;">{{ table_data_index + 1}}</td> -->
                                    <td style="height: 38px;" v-for="(col_data, col_index) in table_info.header_data" :key="col_index">
                                        <input type="text" class="table-list" disabled v-model="data[col_data]" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="itemselected text-left pl-3">
                    No. of items selected:
                    <input type="text" v-model="table_info.no_of_items_selected" disabled style="width: 50px;text-align: center;font-weight: 600;font-size: 14px;color: #303031;" />
                </div>
            </div>
            <div v-if="auction_data.is_auction_expired == false" class="float-right pr-3 mt-6 mb-6">
                <button type="button" class="btn btn-primary btn-lg" :disabled="bidding_items.length == 0" @click="openForm()">Register yourself</button>
            </div>
        </div>
        <bidder-registration v-if="showForm" @hideRegistrationModal="hideRegistrationModal" @saveAndhideRegistrationModal="saveAndhideRegistrationModal" modal_name="Bidder-Registration" :company_id="company_id" :auction_id="auction_id" :bidding_items="bidding_items"></bidder-registration>
    </div>
</template>
<script>
import BidderRegistration from "./BidderRegistration";
import auction from "../mixins/auction.js"
import moment from 'moment';
    export default {
        data() {
            return {
                showForm: false,
                auction_data: [],
                table_data: [],
                table_details: [],
                user_time_zone: "",
                bidding_items: [],
                auction_id: "",
                company_id: "",
            }
        },
        mixins: [auction],
        components:{
            BidderRegistration,
        },
        methods: {
            selectOne(table_info, data, table_info_index, table_data_index){
                let is_table_present = false;
                if(data.checked == true){
                    if(this.bidding_items.length == 0){
                        this.bidding_items.push({
                            table_name: table_info.table_name,
                            table_data: [
                                {
                                    row_id: data.row_id
                                }
                            ],
                        })
                        this.table_details[table_info_index].no_of_items_selected = this.table_details[table_info_index].no_of_items_selected + 1;
                        this.$forceUpdate();
                    } else {
                        this.bidding_items.forEach((el_one, el_one_index) => {
                            if(table_info.table_name == el_one.table_name){
                                this.bidding_items[el_one_index].table_data.push({
                                    row_id: data.row_id
                                })
                                this.table_details[table_info_index].no_of_items_selected = this.table_details[table_info_index].no_of_items_selected + 1;
                                is_table_present = true;
                                // this.$forceUpdate();
                            } else {
                                is_table_present = false;
                            }
                        })
                        if(is_table_present == false){
                            this.bidding_items.push({
                                table_name: table_info.table_name,
                                table_data: [
                                    {
                                        row_id: data.row_id
                                    }
                                ],
                            })
                            this.table_details[table_info_index].no_of_items_selected = this.table_details[table_info_index].no_of_items_selected + 1;
                        }
                        // this.$forceUpdate();
                    }
                    this.$forceUpdate();
                } else if (data.checked == false){
                    this.bidding_items.forEach((bidder_el, bidder_el_index) => {
                        if(table_info.table_name == bidder_el.table_name){
                            bidder_el.table_data.forEach((id_element, id_element_index) => {
                                const indexOfObject = this.bidding_items[bidder_el_index].table_data.findIndex(object => {
                                    return object.row_id === data.row_id;
                                });
                                this.bidding_items[bidder_el_index].table_data.splice(indexOfObject,1);
                            })
                            this.table_details[table_info_index].no_of_items_selected = this.table_details[table_info_index].no_of_items_selected - 1
                        }
                    })
                    this.bidding_items.forEach((bidder_el_delete, bidder_el_delete_index) => {
                        if(bidder_el_delete.table_data.length == 0){
                            this.bidding_items.splice(bidder_el_delete_index, 1)
                        }
                    })
                    this.$forceUpdate();
                }
            },
            openForm(){
                this.showForm = true;
                setTimeout(() => {
                    this.$modal.show("Bidder-Registration");
                }, 500);
            },
            hideRegistrationModal(){
                this.showForm = false;
            },
            saveAndhideRegistrationModal(){
                this.showForm = false;
                this.bidding_items = [];
                this.getAuctionData();
            },
            async getAuctionData() {
                let params = {
                    auction_id: this.auction_id,
                    company_id: this.company_id,
                    timezone: this.user_time_zone
                }
                let response = await this.getAuctionsDetails(params)
                this.auction_data = response.response.auction_data
                this.table_data = response.response.table_data
                this.table_details = response.response.table_data.table_details
                if(this.table_details.length != 0){
                    this.table_details.forEach((el, el_index) => {
                        if(el.table_data.length != 0){
                            el.table_data.forEach((el_data, el_data_index) => {
                                this.table_details[el_index].no_of_items_selected = 0;
                                this.table_details[el_index].table_data[el_data_index].checked = false;
                            })
                        }
                    })
                }
            },
        },
        mounted(){
            this.user_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.company_id = this.$route.query.company_id;
            this.auction_id = this.$route.query.auction_id;
            this.getAuctionData();
        },
    }
</script>
<style scoped>
    .pl-55px{padding-left:55px;}
        @media screen and (max-width:768px) {
            .pl-55px{
                padding-left: 12px;
            }
        }
        .fs-17{font-size:17px}
        .pt-100px{padding-top:100px}
        .lh-35px{line-height:35px;}
        .footer .copyright{
            align-items: center;
            text-align: center;
        }
        .header .mob-soc-media .hover-class,
        .footer .hover-class:hover{
            opacity: .7;
        }
        .header .mob-soc-media .hover-class:hover,
        .footer .hover-class{
            opacity: 1;
        }
        @media screen and (max-width:992px) {
            .footer .footer-list.business-footer{
                width: 25%;
            }
        }
        @media screen and (max-width:768px) {
            .footer .footer-list.business-footer{
                width: 100%;
            }
        }
        @media screen and (max-width: 350px) {
            .height-resp{
                height: 950px !important
            }
        }
        @media screen and (max-width: 768px) {
            .margin-tsm-12px{
                margin-top: 12px
            }
        }
        .auction-header {
            background-color: #08448b;
            color: #fff;
            margin: 10px auto;
        }
        .auction-header h2 {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 500;
            padding: 10px;
        }
        .auction-title {
            color: #557b40;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 35px;
            font-weight: 600;
            padding: 10px;
            margin: 10px 10px;
        }
        .auction-list {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            font-size: 14px;
            margin: 10px 10px;
        }
        .auction-list .listitems {
            text-decoration: underline;
        }
        .auction-list .auction-date {
            font-weight: 600;
        }
        .auction-list .auction-date span {
            font-weight: 500;
        }
        .auction-ci-module-header {
            background-color: #1e92e6;
            color: #fff;
            font-size: 16px;
            display: flex;
            text-align: left;
            font-weight: 600;
            padding: 10px 10px;
            margin: 10px 10px;
            border-radius: 4px;
        }
        .auction-table {
            padding: 10px;
            margin: 10px auto;
        }
        td {
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.83;
            letter-spacing: 0.77px;
            text-align: left;
            color: #303031;
            padding: 8px 15px !important;
            vertical-align: middle;
            border: 1px solid black;
        }
        .itemselected {
            color: #303031;
            font-weight: 600;
        }
        .btn-primary {
            color: #fff !important;
            background-color: #0f5dae;
            border-color: #0f5dae;
            border-radius: 6px;
            font-size: 14px;
            padding: 15px 10px;
            width: 200px;
            height: 54px;
        }
        .open-button {
            background-color: #555;
            color: white;
            padding: 16px 20px;
            border: none;
            cursor: pointer;
            opacity: 0.8;
            position: fixed;
            bottom: 23px;
            right: 28px;
            width: 280px;
        }
        .form-popup {
            display: none;
            position: fixed;
            top: 20%;
            right: 10%;
            border: 3px solid #f1f1f1;
            z-index: 9;
            width: 80%;

        }
        .form-container {
            padding: 10px;
            background-color: white;
        }
        .form-container input[type=text], .form-container input[type=password] {
            width: 100%;
            padding: 15px;
            margin: 5px 0 22px 0;
            border: none;
            background: #f1f1f1;
        }
        .form-container input[type=text]:focus, .form-container input[type=password]:focus {
            background-color: #ddd;
            outline: none;
        }
        .form-container .btn {
            background-color: #08448b;
            color: white;
            border: none;
            cursor: pointer;
            width: 120px;
            opacity: 0.8;
            font-size: 16px;
            text-align: center;
        }
        .form-container .cancel {
            background-color: red;
        }
        .form-container .btn:hover, .open-button:hover {
            opacity: 1;
        }
        .register-header{
            font-size: 24px;
            text-align: center;
            font-weight: 500;
        }
        .small-text{
            font-size: 12px;
            text-align: center;
            padding-top: 12px;
        }
        .register-box{
            width: 100%;
            height: 100%;
            margin: 20px 0 23px;
            padding: 10px 30px 30px 30px !important;
            border-radius: 12px;
            border: solid 1px #949698;
            background-color: #fff;
        }
        .new-contactView {
            display: flex;
            align-items: left;
            position: relative;
        }
        .auction-content-left-text {
            width: 160px!important;
            min-width: 160px!important;
            position: relative;
            font-weight: 600;
            font-size: 14px;
            color: #1f2021 !important;
            margin-top: 10px !important;
        }
        .input-field-underline {
            border: none !important;
            border-bottom: solid 0.5px #cdd2da !important;
            background-color: #fff;
            padding: 3px 10px;
            width: 100%;
        }
        .input-field-underline::placeholder{
            font-size: 14px !important;
            color: #9b9b9b !important;
        }
        input:focus{
            outline: none;
        }
</style>